export const FEES_43113 = [
  { to: 1641430800, feeUsd: "10" },
  { to: 1641954606, feeUsd: "506934.29" },
  { to: 1642557082, feeUsd: "871367.91" },
  { to: 1643166266, feeUsd: "1416130.81" },
  { to: 1643765429, feeUsd: "996296.58" },
  { to: 1644371345, feeUsd: "869493.46" },
  { to: 1644979198, feeUsd: "644299.54" },
  { to: 1645579694, feeUsd: "559829.67" },
  { to: 1646182633, feeUsd: "1041033.13" },
  { to: 1646788780, feeUsd: "525648.29" },
  { to: 1647402672, feeUsd: "491566.54" },
  { to: 1648000896, feeUsd: "523412.47" },
  { to: 1648611331, feeUsd: "482364.71" },
  { to: 1649207157, feeUsd: "819772.21" },
  { to: 1649814371, feeUsd: "536680.30" },
  { to: 1650420021, feeUsd: "297644.50" },
  { to: 1651028602, feeUsd: "329239.03" },
  { to: 1651635172, feeUsd: "344748.08" },
  { to: 1652236772, feeUsd: "809852.39" },
  { to: 1652840724, feeUsd: "850963.06" },
  { to: 1653430967, feeUsd: "306245.8" },
  { to: 1654053714, feeUsd: "539435.12" },
  { to: 1654653280, feeUsd: "840894.12" },
  { to: 1655259566, feeUsd: "506009.43" },
  { to: 1655866071, feeUsd: "526660.33" },
  { to: 1656472723, feeUsd: "448733.62" },
  { to: 1657074613, feeUsd: "392029.38" },
  { to: 1657678876, feeUsd: "348157.7" },
  { to: 1658283954, feeUsd: "475489" },
  { to: 1658886078, feeUsd: "528324.14" },
  { to: 1659494426, feeUsd: "411807.72" },
  { to: 1660095134, feeUsd: "373497.82" },
  { to: 1660698120, feeUsd: "484879.32" },
  { to: 1661309954, feeUsd: "426656.64" },
];
